import styled from 'styled-components';
import { colorFooter } from '../variables';

export const FooterContainer = styled.footer`
  background: #E5E5E5;
  padding: 4em 0;
  color: #23252E;
`;

export const Social = styled.ul`
  display: flex;
  gap: 2em;
  margin-bottom: 4em;

  & a{
      color: #23252E;
      padding: .5em;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
  }
`;