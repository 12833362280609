import styled from 'styled-components';
import { colorPrincipal, colorSecundario, colorTitle } from '../variables';

export const Hero = styled.div`
  display: grid;
  grid-template-columns: 5fr 2fr;
  align-items: center;
  margin-top: 4rem;

  & h1{
    text-transform: uppercase;
    font-size: 5rem;
    color: ${colorTitle};
  }

  & h2{
      font-weight: normal;
      font-size: 2.5rem;
      margin-bottom: 2rem;
  }

  & a{
      background: ${colorPrincipal};
      text-decoration: none;
      border-radius: 2rem;
      padding: .5em 2em;
      color: white;
      font-size: 1.5rem;
  }

  @media (max-width: 500px){
    grid-template-columns: 1fr;
    text-align: center;
    gap: 2em;
    overflow: hidden;
    & h1{
      font-size: 2rem;
    }

    & h2{
      font-size: 1.5rem;
    }
    & a{
      background: ${colorPrincipal};
      text-decoration: none;
      border-radius: 2rem;
      padding: .5em 2em;
      color: white;
      font-size: 1rem;
    }
    & > div:first-child{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    & > div:last-child{
      padding: 0 6em;
    }

  }

`;



export const VideoContainer = styled.section`
  background: ${colorSecundario};
  padding: 8em 0 4em;
  margin: 8em 0 30em;

  @media (max-width: 500px){
    margin-bottom: 18em;
  }
`;

export const Video = styled.div`
  background: #e7ba45d6;
  top: 0;
  border-radius: 8px;
  position: absolute;
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & svg{
      color: white;
      font-size: 4rem;
  }

  @media (max-width: 500px){
    height: 200px;
  }
`;

export const ContactContainer = styled.section`
  background: ${colorPrincipal};
  padding: 8em 4em;
  margin-bottom: 30em;

  @media (max-width: 500px){
    padding: 4em 0 8em;
    margin-bottom: 45em;
  }
`;

export const Iframe = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  height: 600px;
  border-radius: 8px;
  z-index: ${props => props.isOpen && '1000'};
  & iframe{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 500px){
    height: fit-content;
  }
`;

export const Thumbnail = styled.div`
  position: absolute;
  top: 0;
  border-radius: 8px;
  height: 600px;
  overflow: hidden;
  @media (max-width: 500px){
    height: 200px;
  }
`;