import React, { useState } from 'react'
import { StaticImage } from "gatsby-plugin-image"

import {Container} from '../styles/index'
import {Header,LogoContainer,Name,Logo,Navbar,ImageNav} from '../styles/components/Menu'
import { FaBars } from "react-icons/fa";
import { css } from '@emotion/react';

export default function Menu() {

    const [isActive, setIsActive] = useState(false)

    const handleMenuActive = () => {
        setIsActive(!isActive)
    }
    

    return (
        <Container>
            <Header>
                <LogoContainer>
                    <Logo>
                        <StaticImage
                            src="../images/logo-transparent.png"
                        />
                    </Logo>
                </LogoContainer>
                <div
                    css={css`
                        justify-self: end;
                        z-index: 1001;
                    `}
                >

                    <button 
                        css={css`
                            position: ${isActive && 'fixed'};
                            right: .8em;
                            top: 1em;
                        `}
                        onClick={handleMenuActive}
                    ><FaBars/></button>
                </div>
                <Navbar
                    isActive={isActive}
                >
                    <ul>
                        <li>
                            <a href="https://apps.apple.com/co/app/the-bitcoin-app/id1559254669" target="_blank">
                                <ImageNav>
                                    <StaticImage
                                        src="../images/app-store.png"
                                    />
                                </ImageNav>
                            </a>
                        </li>
                        <li>
                            <a href="https://play.google.com/store/apps/details?id=com.ticktaps.bitcoinapp"  target="_blank">
                                <ImageNav>
                                    <StaticImage
                                        src="../images/google-play.png"
                                    />
                                </ImageNav>
                            </a>
                        </li>
                        <li>
                            <a href="#contact" onClick={handleMenuActive} >Support</a>
                        </li>
                    </ul>
                </Navbar>
            </Header>
        </Container>
    )
}
