import styled from 'styled-components';

export const ModalBack = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalContainer = styled.div`
  position: relative;
  top: 8rem;
  background-color: #ffffff;
  padding: 2rem 1rem;
  width: 900px;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 500px){
    width: 98%;
  }
`;

export const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  background-color: #F5F5F5;
  padding: 0.5rem;
  cursor: pointer;
`;