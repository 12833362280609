import styled from 'styled-components';
import { colorPrincipal } from '../variables';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & button{
    display: none;
    border: none;
    color: white;
    background: ${colorPrincipal};
    padding: .5em .8em;
    border-radius: 8px;
  }

  @media (max-width: 500px){
    display: grid;
    grid-template-columns: 1fr 1fr;
    & button{
      display: block;
    }
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Logo = styled.figure`
  width: 70px;
`;

export const Name = styled.strong`
  text-transform: uppercase;
  font-size: 12px;
`;


export const Navbar = styled.nav`
  & ul{
      display: flex;
      gap: 2rem;
      align-items: center;
      
      & li:last-child a{
          background: ${colorPrincipal};
          color: white;
          text-decoration: none;
          border-radius: 8px;
          padding: .5em 2em;
          font-size: 1.2rem;
      }

      @media (max-width: 500px){
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        flex-direction: column;
        justify-content: center;
        background: #253739;

        z-index: 1000;

        display: ${props => props.isActive ? 'flex' : 'none'};

      }
  }
`;

export const ImageNav = styled.div`
  width: 150px;
`;