import React, {useEffect,useState} from 'react'
import { BannerContainer, BannerItems,NavButtons } from '../styles/components/Banner'

export default function Banner({id,children,style,styles}) {

    const [translate, setTranslate] = useState(0)
    const [viewport, setViewport] = useState()
    const [intervalo, setIntervalo] = useState(0)
    const [play, setPlay] = useState(true)

    const [items, setItems] = useState(children)

    let $sliderList
    let $banner
    let $contenido
    let bannerWidth
    let contenidoWidth
    let contador = 0
    let $buttons
    let interval

    useEffect(()=>{
        $banner = document.getElementById(id)
        $contenido = document.getElementById(`items-${id}`)
        $sliderList = [...$contenido.children]
        bannerWidth = $banner.clientWidth;
        contenidoWidth = $contenido.clientWidth
        
        $sliderList.forEach((item)=>{
            if(viewport > 500){
                item.style.minWidth = ((bannerWidth / 3) - 40) + 'px'
            }else{
                item.style.minWidth = bannerWidth + 'px'
            }
        })
        
        
        if(typeof window !== `undefined`){
            setViewport(window.innerWidth)
        }

        $buttons = [...document.querySelectorAll(`.${NavButtons.styledComponentId} div`)];

        $contenido.addEventListener('mouseover', ()=> {
            setPlay(false)
        })
        $contenido.addEventListener('mouseout', ()=>{ 
            setPlay(true)
        } )


        interval = setInterval(()=>{
            if(play){

                setIntervalo(intervalo + 1)
                
                if(intervalo >= $buttons.length -1 ){
                    setIntervalo(0)
                }
    
                setTranslate(-bannerWidth * intervalo)
                
                $buttons.forEach(item => item.classList.remove('active'))
                $buttons[intervalo].classList.add('active')
            }
            


        },8000)

        return ()=> clearInterval(interval)
    })

    const handleBanner = ()=>{

        $buttons.forEach((item,index)=>{
            item.addEventListener('click', function(e){

                setIntervalo(index)
                moveBanner(item,index)

            })
        })
    }

    const moveBanner = (item, index)=>{
        $buttons.forEach(item => item.classList.remove('active'))
        item.classList.add('active')
        setTranslate(-bannerWidth * index)
    }

    return (
        <BannerContainer style={style} styles={styles} id={id}>
            <BannerItems id={`items-${id}`} translate={translate}>
                {children}
            </BannerItems>
            <NavButtons>
                {
                    children.map((item,index)=>{
                        if(viewport > 500){

                            if(((index + 1) % 3) === 1){
                                contador++
                                return <div onClick={handleBanner} class={(contador === 1) && 'active'}></div>
                            }
                        }else{
                            return <div onClick={handleBanner} class={(index === 0) && 'active'}></div>
                        }
                    })
                }
            </NavButtons>
        </BannerContainer>
    )
}

