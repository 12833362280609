import { createGlobalStyle } from "styled-components"
import {fuentePrincipal} from './variables'

export const GlobalStyle = createGlobalStyle`
    html{
        box-sizing: border-box;
    }

    *,*:before,*:after{
        box-sizing: inherit;
    }
    body{
        /* font-size: 1.6rem; */
        line-height: 2;
        font-family: ${fuentePrincipal};
    }

    h1,h2,h3{
        margin: 0;
        line-height: 1.5;
    }
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    figure{
        margin: 0;
    }
    img{
        max-width: 100%;
    }
`;