import React,{useState} from 'react'

import { FaArrowRight } from "react-icons/fa";
import {ContactComponent,Checkmark,LoaderContainer,Success,Error} from '../styles/components/Contact';
import { FaCheckCircle,FaExclamationCircle } from "react-icons/fa";

export default function Contact() {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [checkbox, setCheckbox] = useState(false)
    const [success, setSuccess] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

 

  function handleSubmit(e) {
    setLoading(true)
    e.preventDefault();

    // const bodyEmail = emailTemplate(name)

    send('21million@abitcoinapp.com', `Asunto: ${subject}.<br/>Name: ${name}.<br/>Email: ${email}. <br/>Message: <br/>${message}<br/>I Accept Terms: ${checkbox}`, true)

    // send(email, bodyEmail)

    

    function send(to, body, isToUs){
        window.Email.send({
            SecureToken : "4749d56d-8183-4551-8660-5b20c19dbd41",
            To : `${to}`,
            From : "orbittasd@gmail.com",
            Subject : `${subject}`,
            Body : `${body}`
        }).then(
            message => {
                if(isToUs){

                    setLoading(false)
                    if(message === 'OK'){
                        setSuccess(true)
        
                        setName('')
                        setEmail('')
                        setPhone('')
                        setSubject('')
                        setMessage('')
                        setCheckbox(false)
                    } else{
                        console.log(message)
                        setError(true)
                    }
                }

                message !== 'OK'  && console.log(message)
                
            }
        );
    }
  }



    return (
        <ContactComponent onSubmit={handleSubmit}>
            <input 
                type='text'
                placeholder="Name"
                value={name}
                onChange={e=>{
                    const {value} = e.target
                    
                    setName(value)
                }}
            />
            <input 
                type='email'
                placeholder="Email"
                value={email}
                onChange={e=>{
                    const {value} = e.target

                    setEmail(value)
                }}
            />
            <input 
                type='phone'
                placeholder="Phone"
                value={phone}
                onChange={e=>{
                    const {value} = e.target

                    setPhone(value)
                }}
            />
            <input 
                type='text'
                placeholder="Subject"
                value={subject}
                onChange={e=>{
                    const {value} = e.target

                    setSubject(value)
                }}
            />
            <textarea 
                placeholder="Message"
                value={message}
                onChange={e=>{
                    const {value} = e.target

                    setMessage(value)
                }}
            ></textarea>
            {/* <label>
                <input 
                    type="checkbox" 
                    checked={checkbox}
                    onChange={e=>{
                        const {checked} = e.target
    
                        setCheckbox(checked)
                    }}
                />
                <Checkmark/>
                <a href="#">I Accept Terms & Conditions</a>
            </label> */}
            <button type="submit">Send <FaArrowRight/></button>

            {loading && <LoaderContainer><div></div><div></div><div></div><div></div></LoaderContainer>}
                {
                    success && (
                        <Success>
                            <FaCheckCircle/>
                            <h3>Your email was sent successfully</h3>
                        </Success>
                    )
                }
                {
                    error && (
                        <Error>
                            <FaExclamationCircle/>
                            <h3>An error has occurred. Please try again later.</h3>
                        </Error>
                    )
            }
        </ContactComponent>
    )
}
