import React from 'react';
import ReactDOM from 'react-dom';
import {ModalBack,ModalContainer, Close} from '../styles/components/Modal';
import { FaTimes } from "react-icons/fa";


function Modal({isOpen, onClose,children}) {
  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalBack>
      <ModalContainer>
        <Close onClick={onClose}>
          <FaTimes/>
        </Close>

        {children}
      </ModalContainer>
    </ModalBack>,
    document.getElementById('modal')
  );
}

export default Modal;
