import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useState } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import InfoCard from "../components/InfoCard"
import Contact from "../components/Contact"
import Banner from "../components/Banner"
import Modal from "../components/Modal"
import {Container, Grid} from '../styles/index';
import {Hero,VideoContainer,Video,ContactContainer,Iframe,Thumbnail} from '../styles/pages/index';
import { FaPlay } from "react-icons/fa";
import BenefitsCard from "../components/BenefitsCard"
import { colorPrincipal, colorTitle } from "../styles/variables"
import CustomerCard from '../components/CustomerCard';
import { css } from "@emotion/react"
import {content, benefits,testimonials} from '../api/db.json'

const IndexPage = () => {

  const [isOpen, setIsOpen] = useState(false)

  console.log(isOpen)

return (
  <Layout>
    <Seo title="Home" />
    <section>
      <Container>
        <Hero>
          <div>
            <h1
              style={{
                marginBottom: 0
              }}
            >The bitcoin app</h1>
            <h2>Learn...Evolve...bitcoin</h2>
            <a href="#contact">Contact Us</a>
          </div>
          <div>
            <StaticImage  
              src="../images/hero.png"
              alt="App images"
            />
          </div>
        </Hero>
      </Container>
    </section>

    {
      content.map((item, index)=>{
        if(index % 2){
          return <InfoCard {...item} invert={true} />
        } else{
          return <InfoCard {...item} />
        }
      })
    }

    <VideoContainer>
      <Container>
        <Grid
          custom={'2fr 5fr'}
          gap={4}
          responsive={`
              & > div:first-child{
                width: 60%;
                margin: 0 auto;
              }
          `}
        >
          <div>
            <StaticImage  
              src="../images/bitcoin-icon.png"
              alt="App images"
            />
          </div>

          <div
            style={{
              position: 'relative',
            }}
          >
            <div
              css={css`
                position: absolute;
                width: 100%;
                height: 600px;

              `}
            >
              <Iframe isOpen={isOpen}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/pbsVUB5VnC4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </Iframe>
            </div>
            <Thumbnail>
              <StaticImage  
                src="../images/video-image.jpg"
                alt="What is Bitcon?"
                style={{
                  height: '100%',
                }}
              />
            </Thumbnail>

            <Video onClick={()=>setIsOpen(true)}>
              <FaPlay/>
            </Video>
          </div>

        </Grid>
      </Container>
    </VideoContainer>

    <section>
      <Container>
        <h2
          css={css`
            font-size: 3rem;
            color: ${colorTitle};
            @media (max-width: 500px){
              font-size: 1.5rem;
            }
          `}
        >Happy customers</h2>
      </Container>
        <div
          style={{
            background: colorPrincipal,
            padding: '4em 0'
          }}
        >

          <Container>

              <Banner
                id={`customers`}
              >
                {
                  testimonials.map((item, index)=>(
                    <CustomerCard {...item} key={`customer-${index}`} />
                  ))
                }
              </Banner>
          </Container>

        </div>
    </section>


    <section
      style={{
        margin: '8em 0'
      }}
    >
      <Container>
        <h2
          css={css`
            font-size: 3rem;
            color: ${colorTitle};
            width:40%;
            margin-bottom: 2em;

            @media (max-width: 500px){
              font-size: 1.5rem;
              width: 100%
            }
          `}
        >Benefits of using the app</h2>
        <Grid
          columns={2}
          gap={4}
        >
          {
            benefits.map((item)=> (
              <BenefitsCard {...item}/>
            ))
          }
        </Grid>
      </Container>
    </section>
    <ContactContainer
      id='contact'
    >
      <Container>
        <Grid
          custom={'2fr 5fr'}
          gap={10}
          responsive={`
            gap: 2em; 
            & > div:first-child{
              width: 40%;
              margin: 0 auto;
            }
          `}
        >
          <div>
            <StaticImage  
              src="../images/contact-icon.png"
              alt="Contacto"
            />
          </div>
          <div
            style={{
              position: 'relative'
            }}
          >
            <div
              css={css`
                position: absolute;
                width: 70%;
                @media (max-width: 500px){
                  width: 100%;
                }
              `}
            >
              <h2
                css={css`
                  margin-bottom: 2rem;
                  color: white;
                  font-size: 3rem;
                  @media (max-width: 500px){
                    font-size: 1.5rem;
                  }
                `}
              >Contact Us</h2>
              <Contact/>
            </div>
          </div>


        </Grid>
      </Container>
    </ContactContainer>

    {/* <Modal
      isOpen={isOpen}
      onClose={()=> setIsOpen(false)}
    >
      <Iframe>

        <iframe width="560" height="315" src="https://www.youtube.com/embed/pbsVUB5VnC4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </Iframe>
    </Modal> */}
  </Layout>
)}

export default IndexPage
