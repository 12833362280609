import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import {CustomerCardContainer,Cloud} from '../styles/components/CustomerCard';
import {Grid} from '../styles/index';

export default function CustomerCard({image,testimonial = "Testimonial",name = "Lorem Ipsum",job,title ="Orbittas 1"}) {
    return (
        <CustomerCardContainer>
            <Cloud>
                <h3>{title !== "" ? title : "Lorem Ipsum" }</h3>
                <p>{testimonial}</p>
            </Cloud>

            <Grid
                custom={'1fr 5fr'}
                gap={1}
                responsive={`
                    text-align: center;
                `}
            >
                <figure>
                    <StaticImage  
                        src="../images/avatar.png"
                        alt="Perfil"
                    />
                </figure>
                <div
                    style={{
                        alignSelf:"center"
                    }}
                >
                    <h3>{name}</h3>
                    {
                        job !== "" && <span>{job}</span>
                    }
                </div>

            </Grid>
        </CustomerCardContainer>
    )
}
