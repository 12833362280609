import styled from 'styled-components';

export const Benefit = styled.div`
  display: flex;
  flex: 1 90px;
  gap: 2em;

  & span{
      width: 90px;
      height: 90px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  & div{
    width: 70%
  }

`;