import styled,{css} from 'styled-components';
import { colorTitle,colorPrincipal } from '../variables';

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #F3F3F3;

  &:after{
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid ${colorTitle};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const ContactComponent = styled.form`
  background: rgba(243,243,243,0.6);
  display: grid;
  padding: 1em;
  grid-gap: 1em;

  & input:not([type="checkbox"]), & textarea{
    border: none;
    background: #F3F3F3;
    padding: 1em 2em;
    width: 100%
  }

  & label{
      position: relative;
  }

  & label input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  & label input:checked ~ ${Checkmark}:after{
    display: block;
  }

  & textarea{
      height: 200px;
  }

  & a{
      color: ${colorTitle};
      padding-left: 2em;
  }

  & button{
      background: ${colorPrincipal};
      border: none;
      padding: 1em 8em 1em 3em;
      color: white;
      display: flex;
      align-items: center;
      gap: 1em;
      width: fit-content;
      text-transform: uppercase;
  }
  @media (max-width: 500px){
    grid-gap: .5em;
    & input:not([type="checkbox"]), & textarea{
      padding: .5em 1em;
    }
    & textarea{
      height: 100px;
    }
  }
`;

export const LoaderContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 15px solid ${colorPrincipal};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${colorPrincipal} transparent transparent transparent;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

`;

const infoBox = ()=> css`
  padding: 1em;
  border-radius: 8px;
  display: flex;
  align-items: center;
  & svg{
    font-size: 1.5rem;
    margin-right: 1rem;
  }
  & h3,& svg{
    color: white;
  }
`


export const Success = styled.div`
  background: #81c784;
  ${infoBox}
`;

export const Error = styled.div`
  background: #e57373;
  ${infoBox}
`;