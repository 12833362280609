import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 95%;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.custom ? props.custom : 'repeat('  + props.columns + ',1fr)'};
  grid-gap: ${props => props.gap + 'em'};
  align-items: ${props => props.align};
  & > div:first-child{
    order: ${props => props.invert && '2'};
  }

  @media (max-width: 500px){
    grid-template-columns: 1fr;
    ${props => props.responsive};
  }
`;