import * as React from "react"
import {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from 'react-helmet'

import {GlobalStyle} from '../styles/GlobalStyles'
import Menu from "./Menu"
import Footer from "./Footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    const script = document.createElement('script')
    
    script.src='https://smtpjs.com/v3/smtp.js'
    script.async = true;
    
    document.body.appendChild(script)
  })



  return (
    <>
      <Helmet>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css" integrity="sha512-NhSC1YmyruXifcj/KFRWoC561YpHpc5Jtzgvbuzx5VozKpWvQ+4nXhPdFgmx8xqexRcpAglTj9sIBWINXa8x5w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
      </Helmet>
        <Menu/>
        <main>{children}</main>
        <Footer/>
        <div id="modal"></div>
        <GlobalStyle/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
