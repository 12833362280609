import styled from 'styled-components';
import { colorPrincipal, colorTitle } from '../variables';

export const Left = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  &:before{
      content: '';
      position: absolute;
      background: ${colorPrincipal};
      bottom: 0;
      left: 0;
      right: 0;
      top: 4em;
      border-radius: 8px;
  }
`;

export const ImageContainer = styled.figure`
    width: 55%;
`;

export const Title = styled.h2`
  font-size: 3rem;
  color: ${colorTitle};

  @media (max-width: 500px){
    font-size: 1.5rem;
    text-align: center;
  }
`;