import React from 'react'
import {Benefit} from '../styles/components/BenefitsCard';
import Image from './Image';

export default function BenefitsCard({title,icon,description}) {
    return (
        <Benefit>
            <span>
                <Image
                    filename={icon}
                    alt={title}
                />
            </span>
            <div>
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </Benefit>
    )
}
