import styled from 'styled-components';

export const CustomerCardContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 500px){
    & figure{

      width: 80px;
      margin: 0 auto;
    }
  }
`;

export const Cloud = styled.blockquote`
  background: white;
  border-radius: 10px;
  margin: 0;
  text-align: center;
  padding: 2em;
  margin-bottom: 2em;
  position: relative;
  &:after{
      content: '';
      background: white;
      width: 20px;
      height: 20px;
      clip-path: polygon(0 0, 100% 100%, 0 100%);
      position: absolute;
      transform: rotate(-45deg);
      bottom: -10px;
      left: calc(50% - 10px)
  }
`;
