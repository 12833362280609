import styled from 'styled-components';

export const BannerContainer = styled.div`
  position: relative;
  overflow: hidden;
  ${props => props.styles}
`;

export const BannerItems = styled.div`
  display: flex;
  width: fit-content;
  gap: 3em;
  transform: translate3d(${props => props.translate + 'px'},0,0);
  transition: .3s;
  @media (max-width: 500px){
    gap: 0;
  }
`;

export const NavButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1em;
  margin-top: 3em;
  & div{
    border: 2px solid white;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    cursor: pointer;
    &.active{
      background: white;
    }
  }
`

