import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import {FooterContainer,Social} from '../styles/components/Footer';
import {Container, Grid} from '../styles/index';
import {FaTwitter,FaYoutube } from "react-icons/fa";
import {ImageNav} from '../styles/components/Menu'
import { css } from '@emotion/react';


export default function Footer() {
    return (
        <FooterContainer>
            <Container>
                <Grid
                    custom={'1fr 2fr 1fr'}
                    responsive={`
                        justify-items: center;
                        text-align: center;
                        gap: 2em;
                    `}
                >
                    <div>
                        <StaticImage  
                            src="../images/logo-transparent.png"
                            alt="Logo"
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            flexDirection:'column'
                        }}
                    >
                        <Social>
                            <li>
                                <a href="https://twitter.com/abitcoinapp" target="_blank">
                                    <FaTwitter/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/channel/UCZCaj4NY_HVBkk8n3aqu4cg" target="_blank">
                                    <FaYoutube/>
                                </a>
                            </li>
                        </Social>
                        <p>© 2021 The Bitcoin app. All rights reserved</p>
                    </div>
                    <div
                        css={css`
                            justify-self:end;
                            align-self:center;
                            @media (max-width: 500px){
                                justify-self: center;
                            }
                        `}
                    >
                        <h3
                            style={{
                                fontWeight: 'normal',
                                marginBottom: '1em'
                            }}
                        >Get the App</h3>
                        <ul>
                            <li
                                style={{
                                    marginBottom: '1em'
                                }}
                            >
                                <a href="https://apps.apple.com/co/app/the-bitcoin-app/id1559254669" target="_blank">
                                    <ImageNav>
                                        
                                        <StaticImage
                                            src="../images/app-store.png"
                                        />
                                    </ImageNav>
                                </a>
                            </li>
                            <li>
                                <a href="https://play.google.com/store/apps/details?id=com.ticktaps.bitcoinapp"  target="_blank">
                                    <ImageNav>
                                        <StaticImage
                                            src="../images/google-play.png"
                                        />
                                    </ImageNav>
                                </a>
                            </li>
                        </ul>
                    </div>

                </Grid>
            </Container>
        </FooterContainer>
    )
}
