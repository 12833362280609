import React from 'react'
import ReactHtmlParcer from 'react-html-parser'

import {Container, Grid} from '../styles/index';
import {Left, Title,ImageContainer} from '../styles/components/InfoCard';
import Image from './Image'

export default function InfoCard({image,title,description,invert}) {
    return (
        <section
            style={{
                marginTop: '10em',
            }}
        >
            <Container>
                <Grid
                    columns={2}
                    gap={10}
                    invert={invert}

                    responsive={`
                        gap: 2em;
                        & > div:first-child{
                            order: ${!invert && '2'};
                        }
                    `}

                >
                <Left>
                    <ImageContainer>

                        <Image
                            filename={image}
                            alt={title}
                        />
                    </ImageContainer>
                </Left>
                <div
                    style={{
                        alignSelf: 'center'
                    }}
                >
                    <Title>{title} </Title>
                    <p>{ReactHtmlParcer(description)}</p>
                </div>

                </Grid>
            </Container>
        </section>
    )
}
